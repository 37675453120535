export enum EducationLevel {
    incomplete_primary_education = 'incomplete_primary_education',
    complete_primary_education = 'complete_primary_education',
    incomplete_secondary_education = 'incomplete_secondary_education',
    complete_secondary_education = 'complete_secondary_education',
    incomplete_higher_education = 'incomplete_higher_education',
    complete_higher_education = 'complete_higher_education',
    incomplete_post_graduation = 'incomplete_post_graduation',
    complete_post_graduation = 'complete_post_graduation'
}
