import { all, apply, put, takeLatest } from 'redux-saga/effects'
import {
    loadListMoreTimeSeriesSuccess,
    loadListTimeSeriesFailure,
    loadListTimeSeriesSuccess,
    loadTimeSeriesFailure,
    loadTimeSeriesSuccess,
    viewTimeSeriesFailure,
    viewTimeSeriesSuccess
} from './actions'
import { TimeSeriesTypes } from './types'
import timeSeriesService from '../../../../services/time.series'
import { IActionType, IAxiosResponse, IPaginator } from '../../root.types'
import TimeSeries, { TimeSeriesDatePicker, TimeSeriesType } from '../../../application/models/time.series/time.series'
import Measurement from '../../../application/models/measurements/measurement'
import heartRateVariabilityService from '../../../../services/heart.rate.variability'
import { loadListMoreMeasurementFailure, loadListMoreMeasurementSuccess } from '../actions'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import { loadActivityLevelClassificationRequest, loadActivityLevelSummaryRequest } from '../activity/actions'
import DataSetItem from '../../../application/models/time.series/data.set.item'

function* loadTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter, dataPicker } = action.payload
        let response: TimeSeries = new TimeSeries()
        if (dataPicker === TimeSeriesDatePicker.DAY) {
            if (resource === TimeSeriesType.ACTIVE_MINUTES) {
                const date: string = filter?.date || ''
                yield put(loadActivityLevelSummaryRequest(patientId, date, date))
            }
            response = yield apply(
                timeSeriesService,
                timeSeriesService.getResourceWithInterval,
                [patientId, resource, filter]
            )
        } else if (dataPicker === TimeSeriesDatePicker.WEEK) {
            if (resource === TimeSeriesType.ACTIVE_MINUTES) {
                const startDate: string = filter?.start_date || ''
                const endDate: string = filter?.end_date || ''
                yield put(loadActivityLevelClassificationRequest(patientId, startDate, endDate))
                yield put(loadActivityLevelSummaryRequest(patientId, startDate, endDate))
                response = new TimeSeries().fromJSON({
                    data_set: TimeSeries
                        .generateDaysBetweenDates(startDate, endDate)
                        .map((date: string) => new DataSetItem().fromJSON({ date }))
                })
            } else {
                response = yield apply(
                    timeSeriesService,
                    timeSeriesService.getResource,
                    [patientId, resource, filter]
                )
            }
        } else if (dataPicker === TimeSeriesDatePicker.MONTH) {
            if (resource === TimeSeriesType.ACTIVE_MINUTES) {
                const startDate: string = filter?.start_date || ''
                const endDate: string = filter?.end_date || ''
                yield put(loadActivityLevelClassificationRequest(patientId, startDate, endDate))
                yield put(loadActivityLevelSummaryRequest(patientId, startDate, endDate))
                response = new TimeSeries().fromJSON({
                    data_set: TimeSeries
                        .generateDaysBetweenDates(startDate, endDate)
                        .map((date: string) => new DataSetItem().fromJSON({ date }))
                })
            } else {
                response = yield apply(
                    timeSeriesService,
                    timeSeriesService.getResource,
                    [patientId, resource, filter]
                )
            }
        } else if (dataPicker === TimeSeriesDatePicker.YEAR) {
            response = yield apply(
                timeSeriesService,
                timeSeriesService.getResource,
                [patientId, resource, filter]
            )
        }
        yield put(loadTimeSeriesSuccess(response))
    } catch (e) {
        yield put(loadTimeSeriesFailure())
    }
}

function* loadListTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter } = action.payload
        const response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        yield put(loadListTimeSeriesSuccess(response))
    } catch (e) {
        yield put(loadListTimeSeriesFailure())
    }
}

function* loadListMoreTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter } = action.payload
        const response = yield apply(timeSeriesService, timeSeriesService.getResource, [patientId, resource, filter])
        yield put(loadListMoreTimeSeriesSuccess(response))
    } catch (e) {
        yield put(loadListTimeSeriesFailure())
    }
}

function* viewTimeSeries(action: IActionType) {
    try {
        const { patientId, resource, filter } = action.payload
        const date: string = filter?.date || ''
        yield put(loadActivityLevelSummaryRequest(patientId, date, date))
        const response = yield apply(timeSeriesService, timeSeriesService.getResourceWithInterval, [patientId, resource, filter])
        if (resource === TimeSeriesType.HEART_RATE_VARIABILITY) {
            try {
                const paginator: IPaginator = {
                    first: 0,
                    rows: 10,
                    page: 0,
                    pageCount: 0,
                    totalRecords: 0,
                    search: {
                        key: '',
                        value: ''
                    }
                }
                const hrvFilter: TimeSeriesSimpleFilter = new TimeSeriesSimpleFilter().fromJSON({
                    start_date: filter?.date,
                    end_date: filter?.date
                })
                const hrvResponse: IAxiosResponse<Measurement[]> = yield apply(
                    heartRateVariabilityService,
                    heartRateVariabilityService.getAll,
                    [patientId, paginator, hrvFilter]
                )
                yield put(loadListMoreMeasurementSuccess(hrvResponse))
            } catch (e) {
                yield put(loadListMoreMeasurementFailure())
            }
        }
        yield put(viewTimeSeriesSuccess(response))
    } catch (e) {
        yield put(viewTimeSeriesFailure())
    }
}

export default function* patientTimeSeriesSaga() {
    return yield all([
        takeLatest(TimeSeriesTypes.LOAD_TIMESERIES_REQUEST, loadTimeSeries),
        takeLatest(TimeSeriesTypes.LIST_TIMESERIES_REQUEST, loadListTimeSeries),
        takeLatest(TimeSeriesTypes.LIST_MORE_TIMESERIES_REQUEST, loadListMoreTimeSeries),
        takeLatest(TimeSeriesTypes.VIEW_TIMESERIES_REQUEST, viewTimeSeries)
    ])
}
