import { all, apply, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import userService from '../../../services/users'
import avatarService from '../../../services/users/avatar'
import { IActionType, IAxiosResponse } from '../root.types'
import { open } from '../snack.bar/actions'
import { SnackBarMessageType } from '../../../components/snackbar'
import {
    IActionCreate,
    IActionFind,
    IActionLoad,
    IActionRemove,
    IActionSigopRegistration,
    IActionUpdateLanguage,
    UserLocation,
    UserTypes
} from './types'

import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    getAddressFailure,
    getAddressSuccess,
    getUserLoggedFailure,
    getUserLoggedSuccess,
    loadAvatarFailure,
    loadAvatarSuccess,
    loadFailure,
    loadMoreFailure,
    loadMoreSuccess,
    loadMultipleAvatarFailure,
    loadMultipleAvatarSuccess,
    loadRequest,
    loadSuccess,
    removeAvatarFailure,
    removeAvatarSuccess,
    removeFailure,
    removeSuccess,
    removeUserLoggedFailure,
    removeUserLoggedSuccess,
    sigopRegistrationFailure,
    sigopRegistrationSuccess,
    updateFailure,
    updateLanguageSuccess,
    updateSuccess
} from './actions'
import { changeAvatar, changeBreadCrumbLast, selectPatient } from '../layout/actions'
import Address from '../../application/models/users/address'
import { TypesUser } from '../../application/models/users/users'
import authService from '../../../services/auth'
import { getUserLogged } from '../auth/sagas'
import { loadAssociatesRequest, loadByHealthProfessionalId } from './associates/actions'
import { push } from 'connected-react-router'
import Patient from '../../application/models/users/patient'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { user, avatar, userLocation } = action.payload
        const response: any = yield apply(userService, userService.create, [user])
        let message = 'SNACKBAR.CREATE.MESSAGE'
        if (response.id && avatar?.name) {
            yield call(saveAvatar, response.id, avatar)
            message = 'SNACKBAR.CREATE.MESSAGE_IMG'
        }
        if (user.type === TypesUser.PATIENT) {
            yield put(push(`/app/patients/${response.id}/edit?tab=anthropometric`))
        } else {
            yield put<any>(createSuccess(response))
        }
        yield put(open(SnackBarMessageType.SUCCESS, '', message))
        if (userLocation === UserLocation.LIST) {
            yield call(reloadUsers, user)
        }
        if (userLocation === UserLocation.ASSOCIATES) {
            yield put(loadAssociatesRequest(user.health_professional_id, user.type))
        }
    } catch (e) {
        yield put(createFailure(e))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { userType, paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(userService,
                userService.getAll, [userType, paginator])
        yield put<any>(loadSuccess(response))
        const usersId: string[] = response?.data?.map((adm: any) => adm?.id || '') || []
        yield call(getMultipleAvatar, usersId)
    } catch (e) {
        yield put(loadFailure())
    }
}

function* getById(action: IActionType<IActionFind>) {
    const { id, userType } = action.payload
    try {
        const response: any = yield apply(userService, userService.getById, [id, userType])
        yield put(findSuccess(response))
        yield put(changeBreadCrumbLast(response?.name))
        if (userType === TypesUser.PATIENT) {
            yield put(selectPatient(response))
        }
    } catch (e) {
        yield put(findFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { user, avatar, userLocation } = action.payload
        const response: any = yield apply(userService, userService.update, [user])
        let message = 'SNACKBAR.UPDATE.MESSAGE'
        if (response.id && avatar) {
            yield call(saveAvatar, response.id, avatar)
            message = 'SNACKBAR.UPDATE.MESSAGE_IMG'
        }
        yield put<any>(updateSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', message))
        if (userLocation === UserLocation.LIST) {
            yield call(reloadUsers, user)
        }
        const userLoggedId: string = authService.decodeToken()?.sub || ''
        if (userLoggedId === user?.id) {
            yield call(getUserLogged, user.id, user.type)
        }
    } catch (e) {
        yield put(updateFailure(e))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(userService, userService.remove, [id])
        yield put<any>(removeSuccess(id))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* saveAvatar(userId: string, avatar: any) {
    if (avatar) {
        yield apply(avatarService, avatarService.save, [userId, avatar])
    }
}

function* getAvatar(action: IActionType<IActionFind>) {
    const { id } = action.payload
    try {
        const response: any = yield apply(avatarService, avatarService.get, [id])
        yield put<any>(loadAvatarSuccess(response))
    } catch (e) {
        yield put(loadAvatarFailure())
    }
}

function* removeAvatar(action: IActionType<IActionFind>) {
    const { id } = action.payload
    try {
        yield apply(avatarService, avatarService.remove, [id])
        yield put<any>(removeAvatarSuccess())
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REMOVE.AVATAR'))
        const userLoggedId: string = authService.decodeToken()?.sub || ''
        if (userLoggedId === id) {
            yield put(changeAvatar(''))
        }
    } catch (e) {
        yield put(removeAvatarFailure())
    }
}

export function* getMultipleAvatar(users: string[]) {
    for (const userId of users) {
        try {
            const response: any = yield apply(avatarService, avatarService.get, [userId])
            yield put<any>(loadMultipleAvatarSuccess(userId, response))
        } catch (e) {
            yield put(loadMultipleAvatarFailure(userId))
        }
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { userType, paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(userService, userService.getAll, [userType, paginator])
        yield put<any>(loadMoreSuccess(response))
        const usersId: string[] = response?.data?.map((adm: any) => adm?.id || '') || []
        yield call(getMultipleAvatar, usersId)
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* updateLanguage(action: IActionType<IActionUpdateLanguage>) {
    try {
        const { userId, language, userType } = action.payload
        const response: any = yield apply(userService, userService.updateLanguage, [userId, language, userType])
        yield put<any>(updateLanguageSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.UPDATE.MESSAGE'))
    } catch (e) {
        yield put(updateFailure(e))
    }
}

function* getZipCode(action: IActionType) {
    try {
        const { zip_code, oldAddress } = action.payload
        const response: Address | undefined = yield apply(userService, userService.getZipCode, [zip_code])
        if (response) {
            yield put(getAddressSuccess(response))
        } else {
            yield put(open(SnackBarMessageType.ERROR, '', 'SNACKBAR.UPDATE.ADDRESS.INVALID'))
            yield put(getAddressSuccess(oldAddress))
        }
    } catch (e) {
        yield put(getAddressFailure())
    }
}

function* reloadUsers(user) {
    if (user?.type) {
        if (user?.type === TypesUser.CAREGIVER || user?.type === TypesUser.PATIENT) {
            yield put<any>(loadByHealthProfessionalId(user?.health_professional_id, user.type))
        } else {
            yield put<any>(loadRequest(user?.type))
        }
    }
}

export function* getUserLoggedForDashboard() {
    const userId = authService.decodeToken().sub
    const type = authService.decodeToken().sub_type
    if (userId && type) {
        try {
            const user: any = yield apply(userService, userService.getById, [userId, type])
            yield put(getUserLoggedSuccess(user))
        } catch (e) {
            yield put(getUserLoggedFailure())
        }
    }
}

function* removeUserLogged(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(userService, userService.remove, [id])
        yield put<any>(removeUserLoggedSuccess(id))
        yield apply(authService, authService.logout, [])
        yield put(push(`/`))
    } catch (e) {
        yield put(removeUserLoggedFailure())
    }
}


function* getDataBySigopRegistratioin(action: IActionType<IActionSigopRegistration>) {
    try {
        const { registration } = action.payload
        const data: Patient = yield apply(userService, userService.getDataBySicopRegistration, [registration])
        yield put<any>(sigopRegistrationSuccess(registration, data))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.SIGOP_REGISTRATION.SUCCESS'))
    } catch (e) {
        yield put(sigopRegistrationFailure())
        if (e instanceof Object) {
            const errorData: any = { ...e }
            if (errorData?.code === 404) {
                yield put(
                    open(
                        SnackBarMessageType.ERROR,
                        '',
                        'SNACKBAR.SIGOP_REGISTRATION.ERROR'
                    )
                )
            }
        }
    }
}

export default function* userSaga() {
    return yield all([
        takeLatest(UserTypes.CREATE_REQUEST, create),
        takeLatest(UserTypes.LOAD_REQUEST, getAll),
        takeLatest(UserTypes.FIND_REQUEST, getById),
        takeLatest(UserTypes.UPDATE_REQUEST, update),
        takeLatest(UserTypes.REMOVE_REQUEST, remove),
        takeLatest(UserTypes.LOAD_AVATAR_REQUEST, getAvatar),
        takeLatest(UserTypes.REMOVE_AVATAR_REQUEST, removeAvatar),
        takeEvery(UserTypes.LOAD_MORE_REQUEST, loadMore),
        takeEvery(UserTypes.UPDATE_LANGUAGE_REQUEST, updateLanguage),
        takeLatest(UserTypes.GET_ADDRESS_REQUEST, getZipCode),
        takeLatest(UserTypes.GET_USER_LOGGED_REQUEST, getUserLoggedForDashboard),
        takeLatest(UserTypes.REMOVE_USER_LOGGED_REQUEST, removeUserLogged),
        takeLatest(UserTypes.SIGOP_REGISTRATION_REQUEST, getDataBySigopRegistratioin)
    ])
}
