import { createTypes } from 'reduxsauce'
import { IComponentState } from '../root.types'
import PatientMetrics from '../../application/models/patient.metrics/patient.metrics'
import QuestionnaireStatus from '../../application/models/questionnaires/questionnaire.status'

/**
 * Patient metrics types
 */
export const PatientMetricsTypes = createTypes(`
    RESET
    
    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE

    LOAD_INCOMPLETE_REQUEST
    LOAD_INCOMPLETE_SUCCESS
    LOAD_INCOMPLETE_FAILURE
    `,
    {
        prefix: '@patient.metrics/'
    }
)

export interface IActionsLoadIncompleteMetrics {
    readonly questionnaireStage: number
    readonly ids?: string[]
}

export interface IActionsLoadPatientMetrics {
    readonly questionnaireStage: number
    readonly healthProfessionalId?: string
}

interface IListState extends IComponentState {
    readonly data: PatientMetrics[]
}

interface IIncompleteState extends IComponentState {
    readonly data: QuestionnaireStatus[]
}

/**
 * State type
 */
export interface IPatientMetricsState {
    readonly list: IListState
    readonly incomplete: IIncompleteState
}
