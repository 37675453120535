import { lazy } from 'react'
import { TypesUser } from '../store/application/models/users/users'

const OperationalReports = lazy(() => import('../containers/operational.reports'))

export const OPERATIONAL_REPORTS_ROUTES = [
    {
        path: '/app/operational_reports',
        component: OperationalReports,
        exact: true,
        private: true,
        userTypes: [
            TypesUser.ADMIN,
            TypesUser.HEALTH_PROFESSIONAL
        ]
    }
]
