import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { IActionType, IAxiosResponse } from '../root.types'
import { IActionsLoadIncompleteMetrics, IActionsLoadPatientMetrics, PatientMetricsTypes } from './types'
import { loadSuccess, loadFailure, loadIncompleteSuccess, loadIncompleteFailure, loadIncompleteRequest } from './actions'
import PatientMetrics from '../../application/models/patient.metrics/patient.metrics'
import patientMetricsService from '../../../services/patients.metrics'
import QuestionnaireStatus from '../../application/models/questionnaires/questionnaire.status'
import SimplePatient from '../../application/models/patient.metrics/simple.patitent'

function* loadPatientMetrics(action: IActionType<IActionsLoadPatientMetrics>) {
    try {
        const { questionnaireStage, healthProfessionalId } = action.payload
        const response: IAxiosResponse<PatientMetrics[]> = yield apply(
            patientMetricsService,
            patientMetricsService.getAll,
            [questionnaireStage, healthProfessionalId]
        )
        yield put(loadSuccess(response))
        if (healthProfessionalId) {
            const ids: string[] = response?.data?.length ?
                response.data[0].incomplete_quests_patients?.map((user: SimplePatient) => `${user.id}`) || []
                : []
            yield put(loadIncompleteRequest(questionnaireStage, ids))
        }
    } catch (e) {
        yield put(loadFailure())
    }
}

function* loadIncomplete(action: IActionType<IActionsLoadIncompleteMetrics>) {
    try {
        const { questionnaireStage, ids } = action.payload
        const response: QuestionnaireStatus[] = yield apply(
            patientMetricsService,
            patientMetricsService.getQuestionnaireStatusByIds,
            [questionnaireStage, ids]
        )
        yield put(loadIncompleteSuccess(response))
    } catch (e) {
        yield put(loadIncompleteFailure())
    }
}

export default function* patientMetricsSaga() {
    return yield all([
        takeLatest(PatientMetricsTypes.LOAD_REQUEST, loadPatientMetrics),
        takeLatest(PatientMetricsTypes.LOAD_INCOMPLETE_REQUEST, loadIncomplete)
    ])
}
