/**
 * Action types
 */

import { LanguageOptions } from '../../../i18n'
import Patient from '../../application/models/users/patient'
import { ThemeMode } from '../../../material.theme'

export enum LayoutTypes {
    CHANGE_COLOR = '@layout/CHANGE_COLOR',
    CHANGE_LANGUAGE = '@layout/CHANGE_LANGUAGE',
    CHANGE_USERNAME = '@layout/CHANGE_USERNAME',
    CHANGE_AVATAR = '@layout/CHANGE_AVATAR',
    CHANGE_BREAD_CRUMB = '@layout/CHANGE_BREAD_CRUMB',
    CHANGE_PATIENT = '@layout/CHANGE_PATIENT',
    CHANGE_THEME = '@layout/CHANGE_THEME',
    CHANGE_ANONYMIZATION = '@layout/CHANGE_ANONYMIZATION',
    UPDATE_AVAILABLE = '@layout/UPDATE_AVAILABLE',
    CHANGE_BREAD_CRUMB_ROUTE = '@layout/CHANGE_BREAD_CRUMB_ROUTE'
}

interface AppBarState {
    readonly username: string
    readonly avatar: string
    readonly backgroundColor: string
    readonly iconColor: string
}

/**
 * State type
 */
export interface ILayoutState {
    readonly appBar: AppBarState
    readonly language: LanguageOptions
    readonly breadCrumbLast: string
    readonly breadCrumbRoute: string
    readonly patientSelected: Patient
    readonly themeMode: ThemeMode
    readonly anonymization: boolean
    readonly updateAvailable: boolean
    readonly registration: ServiceWorkerRegistration | undefined
}
