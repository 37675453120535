import { JsonUtils } from '../../utils/json.util'
import { ActivityClassification } from '../../utils/activity.classification'

export default class ActivityLevelClassification {
    private _start_date: string | undefined
    private _end_date: string | undefined
    private _name: ActivityClassification | undefined
    private _patient_id: string | undefined

    get start_date(): string | undefined {
        return this._start_date
    }

    set start_date(value: string | undefined) {
        this._start_date = value
    }

    get end_date(): string | undefined {
        return this._end_date
    }

    set end_date(value: string | undefined) {
        this._end_date = value
    }

    get name(): ActivityClassification | undefined {
        return this._name
    }

    set name(value: ActivityClassification | undefined) {
        this._name = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    public fromJSON(json: any): ActivityLevelClassification {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_date !== undefined) {
            this.start_date = json.start_date
        }

        if (json.end_date !== undefined) {
            this.end_date = json.end_date
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        return this
    }

    public toJSON(): any {
        return {
            start_date: this.start_date ? this.start_date : undefined,
            end_date: this.end_date ? this.end_date : undefined,
            name: this.name ? this.name : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined
        }
    }
}
