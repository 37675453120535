import { action } from 'typesafe-actions'
import { ActivityTypes } from './types'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import PhysicalActivity from '../../../application/models/activity/physical/physical'
import { TimeSeriesIntervalFilter } from '../../../application/models/time.series/filters/interval.filter'
import { IAxiosResponse, IPaginator } from '../../root.types'
import { TimeSeriesFullFilter } from '../../../application/models/time.series/filters/full.filter'
import UtilDatePicker from '../../../../components/date.and.hour/date'
import { loadDashboardRequest } from '../actions'
import { TimeSeriesInterval } from '../../../application/models/time.series/intraday/intraday.summary'
import IntradayTimeSeries from '../../../application/models/time.series/intraday/intraday.time.series'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import ActivityLevelSummary from '../../../application/models/activity/activity.level.summary'
import ActivityLevelClassification from '../../../application/models/activity/activity.level.classification'

export const resetList = () => action(ActivityTypes.RESET_LIST)

export const resetDetails = () => action(ActivityTypes.RESET_DETAILS)

/* Actions to find a physical activity */

export const findActivityRequest = (patientId: string, activityId: string) => action(
    ActivityTypes.FIND_REQUEST,
    { patientId, activityId }
)

export const findActivitySuccess = (activity: PhysicalActivity) => {
    const dashboardFilter: TimeSeriesSimpleFilter = new TimeSeriesSimpleFilter()
        .fromJSON({ start_date: activity?.start_time, end_date: activity?.start_time })
    const patientId: string = activity.patient_id || ''
    const timeSeriesFilter: TimeSeriesFullFilter = new TimeSeriesFullFilter()
        .fromJSON({
            start_date: UtilDatePicker.formatDate(activity?.start_time, 'YYYY-MM-DD'),
            end_date: UtilDatePicker.formatDate(activity?.end_time, 'YYYY-MM-DD'),
            start_time: UtilDatePicker.formatDate(activity?.start_time, 'HH:mm:ss'),
            end_time: UtilDatePicker.formatDate(activity?.end_time, 'HH:mm:ss'),
            interval: TimeSeriesInterval.ONE_SECOND
        })
    return [
        action(
            ActivityTypes.FIND_SUCCESS,
            { activity }
        ),
        loadDashboardRequest(patientId, dashboardFilter, true),
        loadCaloriesRequest(patientId, timeSeriesFilter),
        loadHeartRateRequest(patientId, timeSeriesFilter)
    ]
}

export const findActivityFailure = () => action(ActivityTypes.FIND_FAILURE)

/* Load calories spent during a physical activity */

export const loadCaloriesRequest = (patientId: string, filter: TimeSeriesFullFilter) => action(
    ActivityTypes.LOAD_CALORIES_REQUEST,
    { patientId, filter }
)

export const loadCaloriesSuccess = (calories: IntradayTimeSeries) => action(
    ActivityTypes.LOAD_CALORIES_SUCCESS,
    { calories }
)

export const loadCaloriesFailure = () => action(ActivityTypes.LOAD_CALORIES_FAILURE)

/* Load heart rate during physical activity */

export const loadHeartRateRequest = (patientId: string, filter: TimeSeriesFullFilter) => action(
    ActivityTypes.LOAD_HEART_RATE_REQUEST,
    { patientId, filter }
)

export const loadHeartRateSuccess = (heartRate: IntradayHeartRate) => action(
    ActivityTypes.LOAD_HEART_RATE_SUCCESS,
    { heartRate }
)

export const loadHeartRateFailure = () => action(ActivityTypes.LOAD_HEART_RATE_FAILURE)

/* Actions for physical activities list */

export const changeListActivitiesPaginator = (
    patientId: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter | TimeSeriesIntervalFilter | TimeSeriesFullFilter) => [
    action(ActivityTypes.CHANGE_PAGINATOR, { patientId, paginator, filter }),
    loadActivitiesRequest(patientId, paginator, filter)
]

export const loadActivitiesRequest = (
    patientId: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter | TimeSeriesIntervalFilter | TimeSeriesFullFilter) => action(
    ActivityTypes.LOAD_REQUEST,
    { patientId, paginator, filter }
)

export const loadActivitiesSuccess = (response: IAxiosResponse<PhysicalActivity[]>) => action(
    ActivityTypes.LOAD_SUCCESS,
    {
        activities: response.data,
        headers: response.headers
    }
)

export const loadActivitiesFailure = () => action(ActivityTypes.LOAD_FAILURE)

export const loadMoreActivitiesRequest = (
    patientId: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter | TimeSeriesIntervalFilter | TimeSeriesFullFilter) => action(
    ActivityTypes.LOAD_MORE_REQUEST,
    { patientId, paginator, filter }
)

export const loadMoreActivitiesSuccess = (response: IAxiosResponse<PhysicalActivity[]>) => action(
    ActivityTypes.LOAD_MORE_SUCCESS,
    {
        activities: response.data,
        headers: response.headers
    }
)

export const loadMoreActivitiesFailure = () => action(ActivityTypes.LOAD_MORE_FAILURE)

export const changeDialogRemoveActivities = (dialog: boolean, ids: string[]) => action(
    ActivityTypes.CHANGE_DIALOG_REMOVE,
    {
        dialog,
        ids
    }
)

export const removeActivitiesRequest = (
    patientId: string,
    ids: string[],
    location: 'LIST' | 'DETAILS') => action(
    ActivityTypes.REMOVE_REQUEST,
    { patientId, ids, location }
)

export const removeActivitiesSuccess = (ids: string[]) => action(
    ActivityTypes.REMOVE_SUCCESS,
    { ids }
)

export const removeActivitiesFailure = () => action(ActivityTypes.REMOVE_FAILURE)

export const loadActivityLevelSummaryRequest = (patientId: string, startDate: string, endDate: string) => action(
    ActivityTypes.LOAD_ACTIVITY_LEVEL_SUMMARY_REQUEST,
    { patientId, startDate, endDate }
)

export const loadActivityLevelSummarySuccess = (data: ActivityLevelSummary[]) => action(
    ActivityTypes.LOAD_ACTIVITY_LEVEL_SUMMARY_SUCCESS,
    { data }
)

export const loadActivityLevelSummaryFailure = () => action(
    ActivityTypes.LOAD_ACTIVITY_LEVEL_SUMMARY_FAILURE
)
export const loadActivityLevelClassificationRequest = (patientId: string, startDate: string, endDate: string) => action(
    ActivityTypes.LOAD_ACTIVITY_CLASSIFICATION_REQUEST,
    { patientId, startDate, endDate }
)


export const loadActivityLevelClassificationSuccess = (data: ActivityLevelClassification[]) => action(
    ActivityTypes.LOAD_ACTIVITY_CLASSIFICATION_SUCCESS,
    { data }
)

export const loadActivityLevelClassificationFailure = () => action(
    ActivityTypes.LOAD_ACTIVITY_CLASSIFICATION_FAILURE
)
