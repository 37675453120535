import { all, apply, put, takeEvery, takeLatest } from 'redux-saga/effects'
import moment from 'moment'

import { IActionType, IAxiosResponse } from '../root.types'
import {
    IActionAddMeasurement,
    IActionHRVSync,
    IActionLastHeartRate,
    IActionLastMeasurements,
    IActionLoadDashboard,
    IActionLoadMeasurement,
    IActionRemoveMeasurement,
    IDashboardData,
    ILastMeasurementsData,
    PatientTypes
} from './types'
import {
    addMeasurementFailure,
    addMeasurementSuccess,
    hrvSyncFailure,
    hrvSyncSuccess,
    loadDashboardFailure,
    loadDashboardGaitSpeedFailure,
    loadDashboardGaitSpeedSuccess,
    loadDashboardHRVFailure,
    loadDashboardHRVSuccess,
    loadDashboardSleepFailure,
    loadDashboardSleepSuccess,
    loadDashboardSuccess,
    loadLastHeartRateFailure,
    loadLastHeartRateSuccess,
    loadLastMeasurementFailure,
    loadLastMeasurementSuccess,
    loadListMeasurementFailure,
    loadListMeasurementSuccess,
    loadListMoreMeasurementFailure,
    loadListMoreMeasurementSuccess,
    loadMeasurementFailure,
    loadMeasurementSuccess,
    removeMeasurementFailure,
    removeMeasurementSuccess
} from './actions'
import timeSeriesService from '../../../services/time.series'
import measurementService from '../../../services/measurements'
import heartRateVariabilityService from '../../../services/heart.rate.variability'
import Measurement from '../../application/models/measurements/measurement'
import { TimeSeriesType } from '../../application/models/time.series/time.series'
import { SnackBarMessageType } from '../../../components/snackbar'
import { open } from '../snack.bar/actions'
import { TypeMeasurement } from '../../application/models/measurements/measurements.last'
import HeartRateVariability from '../../application/models/measurements/heart.rate.variability'
import { TimeSeriesIntervalFilter } from '../../application/models/time.series/filters/interval.filter'
import { TimeSeriesFullFilter } from '../../application/models/time.series/filters/full.filter'
import Sleep from '../../application/models/activity/sleep/sleep'
import sleepService from '../../../services/sleep'
import { INITIAL_STATE as SLEEP_INITIAL_STATE } from '../patient/sleep/reducer'
import GaitSpeed from '../../application/models/measurements/gait.speed'
import { MultiStatus } from '../../application/models/multi.status/multi.status'
import { MultiStatusItem } from '../../application/models/multi.status/multi.status.item'
import TSGaitSpeed from '../../application/models/measurements/ts.gait.speed'
import ManualGaitSpeed from '../../application/models/measurements/manual.gait.speed'
import { loadActivityLevelClassificationRequest, loadActivityLevelSummaryRequest } from './activity/actions'

function* loadDashboard(action: IActionType<IActionLoadDashboard>) {
    try {
        const { filter, patientId } = action.payload
        const date: string = moment(filter?.start_date || '').startOf('week').format('YYYY-MM-DD')
        const endDate: string = moment(filter?.start_date || '').endOf('week').format('YYYY-MM-DD')
        yield put(loadActivityLevelSummaryRequest(patientId, `${filter?.start_date}`, `${filter?.start_date}`))
        yield put(loadActivityLevelClassificationRequest(patientId, date, endDate))
        const data: IDashboardData = yield apply(timeSeriesService, timeSeriesService.getAll, [patientId, filter])
        yield put(loadDashboardSuccess(data))
    } catch (e) {
        yield put(loadDashboardFailure())
    }
}

function* loadDashboardSleep(action: IActionType<IActionLoadDashboard>) {
    const { filter, patientId, onlyLastTimeSeries } = action.payload
    if (!onlyLastTimeSeries) {
        const paginator = {
            ...SLEEP_INITIAL_STATE.list.paginator
        }
        try {
            const response: IAxiosResponse<Sleep[]> = yield apply(
                sleepService,
                sleepService.getAll,
                [patientId, paginator, filter])
            yield put(loadDashboardSleepSuccess(response.data))
        } catch (e) {
            yield put(loadDashboardSleepFailure())
        }
    }
}

function* loadDashboardHeartRateVariability(action: IActionType<IActionLoadDashboard>) {
    const { filter, patientId, onlyLastTimeSeries } = action.payload
    if (!onlyLastTimeSeries) {
        try {
            const data: HeartRateVariability = yield apply(
                heartRateVariabilityService,
                heartRateVariabilityService.getByDate,
                [patientId, filter?.start_date]
            )
            yield put(loadDashboardHRVSuccess(data))
        } catch (e) {
            yield put(loadDashboardHRVFailure())
        }
    }
}

function* loadGaitSpeed(action: IActionType<IActionLoadDashboard>) {
    try {
        const { filter, patientId } = action.payload
        const response: IAxiosResponse<Array<GaitSpeed | TSGaitSpeed | ManualGaitSpeed>> = yield apply(
            measurementService,
            measurementService.getAll,
            [patientId,
                `${TypeMeasurement.GAIT_SPEED},${TypeMeasurement.TS_GAIT_SPEED},${TypeMeasurement.MANUAL_GAIT_SPEED}`,
                undefined,
                filter
            ]
        )
        yield put(loadDashboardGaitSpeedSuccess(response?.data || []))
    } catch (e) {
        yield put(loadDashboardGaitSpeedFailure())
    }
}

function* loadLastMeasurementHeartRate(action: IActionType<IActionLastHeartRate>) {
    try {
        const { patientId, filter } = action.payload
        let response: any
        if (filter instanceof TimeSeriesIntervalFilter) {
            response = yield apply(
                timeSeriesService,
                timeSeriesService.getResourceWithInterval,
                [patientId, TimeSeriesType.HEART_RATE, filter]
            )
        }
        if (filter instanceof TimeSeriesFullFilter) {
            response = yield apply(
                timeSeriesService,
                timeSeriesService.getResourceWithTime,
                [patientId, TimeSeriesType.HEART_RATE, filter]
            )
        }
        yield put(loadLastHeartRateSuccess(response))
    } catch (e) {
        yield put(loadLastHeartRateFailure())
    }
}

function* loadLastMeasurement(action: IActionType<IActionLastMeasurements>) {
    try {
        const { patientId } = action.payload
        const response: ILastMeasurementsData = yield apply(measurementService, measurementService.getLast, [patientId])
        yield put(loadLastMeasurementSuccess(response))
    } catch (e) {
        yield put(loadLastMeasurementFailure())
    }
}

function* addMeasure(action: IActionType<IActionAddMeasurement>) {
    try {
        const { measure, location } = action.payload
        const response: any = yield apply(measurementService, measurementService.addMeasurement, [measure])
        yield put<any>(addMeasurementSuccess(response, measure?.patient_id ? measure.patient_id : '', location))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'PATIENTS.MEASUREMENTS.SNACKBAR.MESSAGE'))
    } catch (e) {
        yield put(addMeasurementFailure())
    }
}

function* loadMeasurementsForChart(action: IActionType<IActionLoadMeasurement>) {
    try {
        const { patientId, paginator, filter, type } = action.payload
        const response: IAxiosResponse<Measurement[]> = type === TypeMeasurement.HEART_RATE_VARIABILITY ?
            yield apply(
                heartRateVariabilityService,
                heartRateVariabilityService.getAll,
                [patientId, paginator, filter]
            ) : yield apply(
                measurementService,
                measurementService.getAll,
                [patientId, type, paginator, filter]
            )
        yield put(loadMeasurementSuccess(response))
    } catch (e) {
        yield put(loadMeasurementFailure())
    }
}

function* loadListMeasurements(action: IActionType<IActionLoadMeasurement>) {
    try {
        const { patientId, paginator, filter, type } = action.payload
        const response: IAxiosResponse<Measurement[]> = type === TypeMeasurement.HEART_RATE_VARIABILITY ?
            yield apply(
                heartRateVariabilityService,
                heartRateVariabilityService.getAll,
                [patientId, paginator, filter]
            )
            : yield apply(
                measurementService,
                measurementService.getAll,
                [patientId, type, paginator, filter]
            )
        yield put(loadListMeasurementSuccess(response))
    } catch (e) {
        yield put(loadListMeasurementFailure())
    }
}

function* loadListMoreMeasurements(action: IActionType<IActionLoadMeasurement>) {
    try {
        const { patientId, paginator, filter, type } = action.payload
        const response: IAxiosResponse<Measurement[]> = type === TypeMeasurement.HEART_RATE_VARIABILITY ?
            yield apply(
                heartRateVariabilityService,
                heartRateVariabilityService.getAll,
                [patientId, paginator, filter]
            )
            : yield apply(
                measurementService,
                measurementService.getAll,
                [patientId, type, paginator, filter]
            )
        yield put(loadListMoreMeasurementSuccess(response))
    } catch (e) {
        yield put(loadListMoreMeasurementFailure())
    }
}

function* removeMeasurements(action: IActionType<IActionRemoveMeasurement>) {
    try {
        const { patientId, ids, type } = action.payload
        for (const measurementId of ids) {
            type === TypeMeasurement.HEART_RATE_VARIABILITY ?
                yield apply(
                    heartRateVariabilityService,
                    heartRateVariabilityService.remove,
                    [patientId, measurementId]
                )
                : yield apply(
                    measurementService,
                    measurementService.remove,
                    [patientId, measurementId]
                )
        }
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.MEASUREMENTS.REMOVE.REMOVED_SUCCESS')
        )
        yield put(removeMeasurementSuccess(ids, type))
    } catch (e) {
        yield put(removeMeasurementFailure())
    }
}

function* hrvSyncData(action: IActionType<IActionHRVSync>) {
    const { patientId, startDate, endDate } = action.payload
    try {
        const response: MultiStatus<HeartRateVariability> = yield apply(
            heartRateVariabilityService,
            heartRateVariabilityService.requestSync,
            [patientId, startDate, endDate]
        )
        const resultSuccess: Array<HeartRateVariability | undefined> = response
            ?.success
            ?.map((successItem: MultiStatusItem<HeartRateVariability>) => successItem.item) || []
        yield put(hrvSyncSuccess(resultSuccess, startDate, endDate))
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.MEASUREMENTS.HEART_RATE_VARIABILITY.REQUEST_SYNC.SUCCESS')
        )
    } catch (e) {
        yield put(hrvSyncFailure(startDate, endDate))
    }
}

export default function* patientSaga() {
    return yield all([
        takeLatest(PatientTypes.LOAD_DASHBOARD_REQUEST, loadDashboard),
        takeLatest(PatientTypes.LOAD_DASHBOARD_REQUEST, loadDashboardSleep),
        takeLatest(PatientTypes.LOAD_DASHBOARD_REQUEST, loadDashboardHeartRateVariability),
        takeLatest(PatientTypes.LOAD_DASHBOARD_REQUEST, loadGaitSpeed),
        takeLatest(PatientTypes.LOAD_LAST_HEART_RATE_REQUEST, loadLastMeasurementHeartRate),
        takeLatest(PatientTypes.LOAD_LAST_MEASUREMENT_REQUEST, loadLastMeasurement),
        takeLatest(PatientTypes.ADD_MEASUREMENT_REQUEST, addMeasure),
        takeLatest(PatientTypes.LOAD_MEASUREMENT_REQUEST, loadMeasurementsForChart),
        takeLatest(PatientTypes.LOAD_LIST_MEASUREMENT_REQUEST, loadListMeasurements),
        takeLatest(PatientTypes.LOAD_MORE_LIST_MEASUREMENT_REQUEST, loadListMoreMeasurements),
        takeLatest(PatientTypes.REMOVE_MEASUREMENT_REQUEST, removeMeasurements),
        takeEvery(PatientTypes.HRV_SYNC_REQUEST, hrvSyncData)
    ])
}
