import { JsonUtils } from '../../utils/json.util'

export default class SimplePatient {
    private _id: string | undefined
    private _name: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    public fromJSON(json: any): SimplePatient {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id || undefined,
            name: this.name || undefined
        }
    }
}
