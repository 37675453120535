import { action } from 'typesafe-actions'
import { PatientMetricsTypes } from './types'
import { IAxiosResponse } from '../root.types'
import PatientMetrics from '../../application/models/patient.metrics/patient.metrics'
import QuestionnaireStatus from '../../application/models/questionnaires/questionnaire.status'

/* Load patients metrics */

export const reset = () => action(PatientMetricsTypes.RESET)

export const loadRequest = (questionnaireStage: number, healthProfessionalId?: string) => action(
    PatientMetricsTypes.LOAD_REQUEST,
    { questionnaireStage, healthProfessionalId }
)

export const loadSuccess = (response: IAxiosResponse<PatientMetrics[]>) => action(
    PatientMetricsTypes.LOAD_SUCCESS,
    response
)

export const loadFailure = () => action(PatientMetricsTypes.LOAD_FAILURE)

export const loadIncompleteRequest = (questionnaireStage: number, ids: string[]) => action(
    PatientMetricsTypes.LOAD_INCOMPLETE_REQUEST,
    { questionnaireStage, ids }
)

export const loadIncompleteSuccess = (data: QuestionnaireStatus[]) => action(
    PatientMetricsTypes.LOAD_INCOMPLETE_SUCCESS, { data }
)

export const loadIncompleteFailure = () => action(PatientMetricsTypes.LOAD_INCOMPLETE_FAILURE)