import { action } from 'typesafe-actions'
import { TimeSeriesTypes } from './types'
import TimeSeries, { TimeSeriesDatePicker, TimeSeriesType } from '../../../application/models/time.series/time.series'
import { TimeSeriesIntervalFilter } from '../../../application/models/time.series/filters/interval.filter'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import { TimeSeriesFullFilter } from '../../../application/models/time.series/filters/full.filter'

/* Actions for change */
export const changeDialogTimeSeries = (dialog: boolean, date?: string) =>
    action(TimeSeriesTypes.CHANGE_DIALOG_TIMESERIES, { dialog, date })

/* Actions for view timeseries */
export const viewTimeSeriesRequest = (patientId: string, resource: TimeSeriesType, filter: TimeSeriesIntervalFilter) =>
    action(TimeSeriesTypes.VIEW_TIMESERIES_REQUEST, { patientId, resource, filter })

export const viewTimeSeriesSuccess = (timeseries: TimeSeries) => action(
    TimeSeriesTypes.VIEW_TIMESERIES_SUCCESS,
    { timeseries }
)

export const viewTimeSeriesFailure = () => action(TimeSeriesTypes.VIEW_TIMESERIES_FAILURE)

/* Actions for timeseries */
export const loadTimeSeriesRequest = (
    patientId: string,
    resource: TimeSeriesType,
    filter: TimeSeriesSimpleFilter | TimeSeriesIntervalFilter | TimeSeriesFullFilter,
    dataPicker: TimeSeriesDatePicker) =>
    action(TimeSeriesTypes.LOAD_TIMESERIES_REQUEST, {
        patientId,
        resource,
        filter,
        dataPicker
    })

export const loadTimeSeriesSuccess = (timeseries: TimeSeries) => action(TimeSeriesTypes.LOAD_TIMESERIES_SUCCESS, { timeseries })

export const loadTimeSeriesFailure = () => action(TimeSeriesTypes.LOAD_TIMESERIES_FAILURE)

/* Actions for list time series */
export const loadListTimeSeriesRequest = (
    patientId: string,
    resource: TimeSeriesType,
    filter: TimeSeriesSimpleFilter | TimeSeriesIntervalFilter | TimeSeriesFullFilter) =>
    action(TimeSeriesTypes.LIST_TIMESERIES_REQUEST, { patientId, resource, filter })

export const loadListTimeSeriesSuccess = (timeseries: TimeSeries) => action(TimeSeriesTypes.LIST_TIMESERIES_SUCCESS, { timeseries })

export const loadListTimeSeriesFailure = () => action(TimeSeriesTypes.LIST_TIMESERIES_FAILURE)

export const loadListMoreTimeSeriesRequest =
    (patientId: string,
     resource: TimeSeriesType,
     filter: TimeSeriesSimpleFilter | TimeSeriesIntervalFilter | TimeSeriesFullFilter) =>
        action(TimeSeriesTypes.LIST_MORE_TIMESERIES_REQUEST, { patientId, resource, filter })

export const loadListMoreTimeSeriesSuccess = (timeseries: TimeSeries) => action(
    TimeSeriesTypes.LIST_MORE_TIMESERIES_SUCCESS,
    { timeseries }
)

export const loadListMoreTimeSeriesFailure = () => action(TimeSeriesTypes.LIST_MORE_TIMESERIES_FAILURE)
