import axiosInstance from './axios'
import PatientMetrics from '../store/application/models/patient.metrics/patient.metrics'
import { IAxiosResponse } from '../store/ducks/root.types'
import QuestionnaireStatus from '../store/application/models/questionnaires/questionnaire.status'

class PatientMetricsService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAll(stage: number, healthProfessionalId?: string): Promise<IAxiosResponse<PatientMetrics[]>> {
        const params = new URLSearchParams()
        if (healthProfessionalId) {
            params.append('healthprofessional_id', healthProfessionalId)
        }
        return axiosInstance
            .get(`${this.apiVersion}/patientmetrics/${stage}`, { params })
            .then(response => {
                return {
                    data: response.data.map((item: any) => new PatientMetrics().fromJSON(item)),
                    headers: response.headers
                }
            })
    }

    public getQuestionnaireStatusByIds(questStage: number, ids?: string[]): Promise<QuestionnaireStatus[]> {
        const params = new URLSearchParams()
        if (questStage) {
            params.append('stage', `${questStage}`)
        }
        if (ids?.length) {
            params.append('patient_id', ids?.join(',') || '')
        }
        return axiosInstance
            .get(`${this.apiVersion}/questionnaires`, { params })
            .then(response => {
                return response
                    ?.data
                    ?.map((item: any) =>
                        new QuestionnaireStatus().fromJSON({
                            has_biodemographic_record: Boolean(item?.biodemographic_record),
                            has_occupational_record: Boolean(item?.occupational_record),
                            has_laboratory_record: Boolean(item?.laboratory_record),
                            has_bioimpedance_record: Boolean(item?.bioimpedance_record),
                            patient_id: item.patient_id
                        })
                    )
            })
    }
}

export default new PatientMetricsService()
