import { createTypes } from 'reduxsauce'
import TimeSeries from '../../../application/models/time.series/time.series'
import { IComponentState } from '../../root.types'

/**
 * Patient types
 */
export const TimeSeriesTypes = createTypes(`
    CHANGE_DIALOG_TIMESERIES

    VIEW_TIMESERIES_REQUEST
    VIEW_TIMESERIES_SUCCESS
    VIEW_TIMESERIES_FAILURE
    
    LOAD_TIMESERIES_REQUEST
    LOAD_TIMESERIES_SUCCESS
    LOAD_TIMESERIES_FAILURE

    LIST_TIMESERIES_REQUEST
    LIST_TIMESERIES_SUCCESS
    LIST_TIMESERIES_FAILURE
    
    LIST_MORE_TIMESERIES_REQUEST
    LIST_MORE_TIMESERIES_SUCCESS
    LIST_MORE_TIMESERIES_FAILURE
`,
    {
        prefix: '@patient_timeseries/'
    }
)

export interface DialogViewTimeSeries {
    readonly dialog: boolean
    readonly date: string
}

export interface ILoadTimeSeries extends IComponentState {
    readonly timeseries: TimeSeries
}

/**
 * State type
 */
export interface IPatientTimeSeriesState {
    readonly dialog: DialogViewTimeSeries
    readonly chart: ILoadTimeSeries
    readonly view: ILoadTimeSeries
    readonly list: ILoadTimeSeries
}
