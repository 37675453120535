import { EducationLevel } from '../../utils/education.level'
import { JsonUtils } from '../../utils/json.util'

export enum MaritalStatus {
    SINGLE = 'single',
    MARRIED = 'married',
    LIVING_TOGETHER = 'living_together',
    DIVORCED = 'divorced',
    SEPARATED = 'separated',
    WIDOWER = 'widower'
}

export enum Deficiency {
    NONE = 'none',
    HEARING = 'hearing',
    VISUAL = 'visual',
    PHYSICAL = 'physical',
    INTELLECTUAL = 'intellectual'
}

export enum DeficiencyType {
    HEARING = 'hearing',
    VISUAL = 'visual',
    PHYSICAL = 'physical',
    INTELLECTUAL = 'intellectual'
}

export enum YesOrNo {
    YES = 'yes',
    NO = 'no'
}

export default class Sociodemographic {
    // Readonly
    private _created_at: string | undefined
    private _updated_at: string | undefined
    // Required
    private _profession: string | undefined
    private _marital_status: MaritalStatus | undefined
    private _live_alone: string | undefined
    // Optional
    private _deficiency: Deficiency[] | undefined
    private _live_family: string | undefined
    private _live_spouse: string | undefined
    private _live_institution: string | undefined
    private _education_level: EducationLevel | undefined

    constructor() {
        this._marital_status = '' as MaritalStatus
        this.education_level = '' as EducationLevel
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get profession(): string | undefined {
        return this._profession
    }

    set profession(value: string | undefined) {
        this._profession = value
    }

    get marital_status(): MaritalStatus | undefined {
        return this._marital_status
    }

    set marital_status(value: MaritalStatus | undefined) {
        this._marital_status = value
    }

    get live_alone(): string | undefined {
        return this._live_alone
    }

    set live_alone(value: string | undefined) {
        this._live_alone = value
    }

    get deficiency(): Deficiency[] | undefined {
        return this._deficiency
    }

    set deficiency(value: Deficiency[] | undefined) {
        this._deficiency = value
    }

    get live_family(): string | undefined {
        return this._live_family
    }

    set live_family(value: string | undefined) {
        this._live_family = value
    }

    get live_spouse(): string | undefined {
        return this._live_spouse
    }

    set live_spouse(value: string | undefined) {
        this._live_spouse = value
    }

    get live_institution(): string | undefined {
        return this._live_institution
    }

    set live_institution(value: string | undefined) {
        this._live_institution = value
    }

    get education_level(): EducationLevel | undefined {
        return this._education_level
    }

    set education_level(value: EducationLevel | undefined) {
        this._education_level = value
    }

    public fromJSON(json: any): Sociodemographic {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }


        if (json.profession !== undefined) {
            this.profession = json.profession
        }

        if (json.marital_status !== undefined) {
            this.marital_status = json.marital_status
        }

        if (json.live_alone !== undefined) {
            this.live_alone = json.live_alone
        }

        if (json.deficiency !== undefined) {
            this.deficiency = json.deficiency
        }

        if (json.live_family !== undefined) {
            this.live_family = json?.live_alone === YesOrNo.YES ? undefined : json.live_family
        }

        if (json.live_spouse !== undefined) {
            this.live_spouse = json?.live_alone === YesOrNo.YES ? undefined : json.live_spouse
        }

        if (json.live_institution !== undefined) {
            this.live_institution = json?.live_alone === YesOrNo.YES ? undefined : json.live_institution
        }

        if (json.education_level !== undefined) {
            this.education_level = EducationLevel[json?.education_level]
        }

        return this
    }

    public toJSON(): any {
        return {
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            profession: this.profession ? this.profession : undefined,
            marital_status: this.marital_status ? this.marital_status : undefined,
            live_alone: this.live_alone ? this.live_alone : undefined,
            deficiency: this.deficiency ? this.deficiency : undefined,
            live_family: this.live_family ? this.live_family : undefined,
            live_spouse: this.live_spouse ? this.live_spouse : undefined,
            live_institution: this.live_institution ? this.live_institution : undefined,
            education_level: this.education_level ? this.education_level : undefined
        }
    }
}
