import { JsonUtils } from '../../utils/json.util'
import SimplePatient from './simple.patitent'

export default class PatientMetrics {
    private _questionnaire_stage: number | undefined
    private _patients_number: number | undefined
    private _active_fitbit_patients: SimplePatient[] | undefined
    private _inactive_fitbit_patients: SimplePatient[] | undefined
    private _complete_quests_patients: SimplePatient[] | undefined
    private _incomplete_quests_patients: SimplePatient[] | undefined
    private _healthprofessional_id: string | undefined
    private _healthprofessional_name: string | undefined

    get questionnaire_stage(): number | undefined {
        return this._questionnaire_stage
    }

    set questionnaire_stage(value: number | undefined) {
        this._questionnaire_stage = value
    }

    get patients_number(): number | undefined {
        return this._patients_number
    }

    set patients_number(value: number | undefined) {
        this._patients_number = value
    }

    get active_fitbit_patients(): SimplePatient[] | undefined {
        return this._active_fitbit_patients
    }

    set active_fitbit_patients(value: SimplePatient[] | undefined) {
        this._active_fitbit_patients = value
    }

    get inactive_fitbit_patients(): SimplePatient[] | undefined {
        return this._inactive_fitbit_patients
    }

    set inactive_fitbit_patients(value: SimplePatient[] | undefined) {
        this._inactive_fitbit_patients = value
    }

    get complete_quests_patients(): SimplePatient[] | undefined {
        return this._complete_quests_patients
    }

    set complete_quests_patients(value: SimplePatient[] | undefined) {
        this._complete_quests_patients = value
    }

    get incomplete_quests_patients(): SimplePatient[] | undefined {
        return this._incomplete_quests_patients
    }

    set incomplete_quests_patients(value: SimplePatient[] | undefined) {
        this._incomplete_quests_patients = value
    }

    get healthprofessional_id(): string | undefined {
        return this._healthprofessional_id
    }

    set healthprofessional_id(value: string | undefined) {
        this._healthprofessional_id = value
    }

    get healthprofessional_name(): string | undefined {
        return this._healthprofessional_name
    }

    set healthprofessional_name(value: string | undefined) {
        this._healthprofessional_name = value
    }

    public fromJSON(json: any): PatientMetrics {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.questionnaire_stage !== undefined) {
            this.questionnaire_stage = json.questionnaire_stage
        }

        if (json.patients_number !== undefined) {
            this.patients_number = Number(json.patients_number)
        }

        if (json?.active_fitbit_patients?.length) {
            this.active_fitbit_patients = json
                ?.active_fitbit_patients
                ?.map((user: any) => new SimplePatient().fromJSON({ id: user, name: user }))
        }

        if (json?.inactive_fitbit_patients?.length) {
            this.inactive_fitbit_patients = json
                ?.inactive_fitbit_patients
                ?.map((user: any) => new SimplePatient().fromJSON({ id: user, name: user }))
        }

        if (json?.complete_quests_patients?.length) {
            this.complete_quests_patients = json
                ?.complete_quests_patients
                ?.map((user: any) => new SimplePatient().fromJSON({ id: user, name: user }))
        }

        if (json?.incomplete_quests_patients?.length) {
            this.incomplete_quests_patients = json
                ?.incomplete_quests_patients
                ?.map((user: any) => new SimplePatient().fromJSON(user))
        }

        if (json.healthprofessional_id !== undefined) {
            this.healthprofessional_id = json.healthprofessional_id
        }

        if (json.healthprofessional_name !== undefined) {
            this.healthprofessional_name = json.healthprofessional_name
        }

        return this
    }

    public toJSON(): any {
        return {
            questionnaire_stage: this.questionnaire_stage ? this.questionnaire_stage : undefined,
            patients_number: this.patients_number ? this.patients_number : undefined,
            active_fitbit_patients: this.active_fitbit_patients?.length ?
                this.active_fitbit_patients?.map((user: SimplePatient) => user.toJSON())
                : [],
            inactive_fitbit_patients: this.inactive_fitbit_patients?.length ?
                this.inactive_fitbit_patients?.map((user: SimplePatient) => user.toJSON())
                : [],
            complete_quests_patients: this.complete_quests_patients?.length ?
                this.complete_quests_patients?.map((user: SimplePatient) => user.toJSON())
                : [],
            incomplete_quests_patients: this.incomplete_quests_patients?.length ?
                this.incomplete_quests_patients?.map((user: SimplePatient) => user.toJSON())
                : [],
            healthprofessional_id: this.healthprofessional_id ? this.healthprofessional_id : undefined,
            healthprofessional_name: this.healthprofessional_name ? this.healthprofessional_name : undefined
        }
    }
}
