import { lazy } from 'react'
import { TypesUser } from '../store/application/models/users/users'

const List = lazy(() => import('../containers/users/patients/list.by.type'))
const Profile = lazy(() => import('../containers/users/patients/profile'))
const Create = lazy(() => import('../containers/users/patients/create'))
const Dashboard = lazy(() => import('../containers/users/patients/dashboard'))
const LastMeasurement = lazy(() => import('../containers/users/patients/measurements'))
const TimeSeriesHandler = lazy(() => import('../containers/users/patients/timeseries/time.series'))
const PhysicalActivities = lazy(() => import('../containers/users/patients/activity/history'))
const PhysicalActivitiesDetails = lazy(() => import('../containers/users/patients/activity/details'))
const ListOfNotes = lazy(() => import('../containers/users/patients/notes/list'))
const Sleep = lazy(() => import('../containers/users/patients/sleep/history'))
const SleepDetails = lazy(() => import('../containers/users/patients/sleep/details'))
const ListOfDevices = lazy(() => import('../containers/users/patients/device/list'))
const Report = lazy(() => import('../containers/users/patients/report/list'))
const NewReport = lazy(() => import('../containers/users/patients/report'))
const Questionnaires = lazy(() => import('../containers/users/patients/questionnaires/index'))

export const PATIENT_ROUTES = [
    {
        path: '/app/patients',
        component: List,
        exact: true,
        private: true,
        scopes: ['pa:r'],
        userTypes: [
            TypesUser.ADMIN,
            TypesUser.HEALTH_PROFESSIONAL,
            TypesUser.CAREGIVER
        ]
    },
    {
        path: '/app/patients/new',
        component: Create,
        exact: true,
        private: true,
        scopes: ['pa:c']
    },
    {
        path: '/app/patients/:userId/edit',
        component: Create,
        exact: true,
        private: true,
        scopes: ['pa:r', 'pa:u'],
        userTypes: [
            TypesUser.ADMIN,
            TypesUser.HEALTH_PROFESSIONAL,
            TypesUser.CAREGIVER
        ]
    },
    {
        path: '/app/patients/:userId',
        component: Profile,
        exact: true,
        private: true,
        scopes: ['pa:r']
    },
    {
        path: '/app/patients/:userId/time_series',
        component: Dashboard,
        exact: true,
        private: true,
        scopes: ['pa:r', 'ts:r']
    },
    {
        path: '/app/patients/:userId/measurements',
        component: LastMeasurement,
        exact: true,
        private: true,
        scopes: ['pa:r', 'me:r']
    },
    {
        path: '/app/patients/:userId/time_series/:resource',
        component: TimeSeriesHandler,
        exact: true,
        private: true,
        scopes: ['ts:r']
    },
    {
        path: '/app/patients/:userId/measurements/:resource',
        component: TimeSeriesHandler,
        exact: true,
        private: true,
        scopes: ['me:r']
    },
    {
        path: '/app/patients/:userId/physical_activities',
        component: PhysicalActivities,
        exact: true,
        private: true,
        scopes: ['ac:r']
    },
    {
        path: '/app/patients/:userId/physical_activities/:activityId',
        component: PhysicalActivitiesDetails,
        exact: true,
        private: true,
        scopes: ['ac:r']
    },
    {
        path: '/app/patients/:userId/diagnosis',
        component: ListOfNotes,
        exact: true,
        private: true,
        scopes: ['mr:r']
    },
    {
        path: '/app/patients/:userId/sleep',
        component: Sleep,
        exact: true,
        private: true,
        scopes: ['sl:r']
    },
    {
        path: '/app/patients/:userId/sleep/:sleepId',
        component: SleepDetails,
        exact: true,
        private: true,
        scopes: ['sl:r']
    },
    {
        path: '/app/patients/:userId/devices',
        component: ListOfDevices,
        exact: true,
        private: true,    
        scopes: ['de:r']
    },
    {
        path: '/app/patients/:userId/report',
        component: Report,
        exact: true,
        private: true
    },
    {
        path: '/app/patients/:userId/report/new',
        component: NewReport,
        exact: true,
        private: true
    },
    {
        path: '/app/patients/:userId/evolutions',
        component: ListOfNotes,
        exact: true,
        private: true,
        scopes: ['mr:r']
    },
    {
        path: '/app/patients/:userId/questionnaires',
        component: Questionnaires,
        exact: true,
        private: true,
        scopes: ['qt:r']
    },
    {
        path: '/app/patients/:userId/questionnaires/new',
        component: Questionnaires,
        exact: true,
        private: true,
        scopes: ['qt:c']
    },
    {
        path: '/app/patients/:userId/questionnaires/:questionnaireId',
        component: Questionnaires,
        exact: true,
        private: true,
        scopes: ['qt:r', 'qt:u']
    }
]
