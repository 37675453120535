import { Reducer } from 'redux'
import { ILayoutState, LayoutTypes } from './types'
import CONFIG_THEME, { ThemeMode } from '../../../material.theme'
import i18n, { MAP_LANGUAGE_TO_ENUM } from '../../../i18n'
import Patient from '../../application/models/users/patient'

const INITIAL_STATE: ILayoutState = {
    appBar: {
        username: '',
        avatar: '',
        backgroundColor: CONFIG_THEME[ThemeMode.LIGHT].palette.white.main,
        iconColor: CONFIG_THEME[ThemeMode.LIGHT].palette.text.primary
    },
    language: MAP_LANGUAGE_TO_ENUM[i18n.language],
    breadCrumbLast: '',
    breadCrumbRoute: '',
    patientSelected: new Patient(),
    themeMode: ThemeMode.LIGHT,
    anonymization: false,
    updateAvailable: false,
    registration: undefined
}

const reducer: Reducer<ILayoutState> = (state: ILayoutState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case LayoutTypes.CHANGE_COLOR:
            const { backgroundColor, iconColor } = action.payload
            return { ...state, appBar: { ...state.appBar, backgroundColor, iconColor } }

        case LayoutTypes.CHANGE_LANGUAGE:
            const { language } = action.payload
            return { ...state, language }

        case LayoutTypes.CHANGE_BREAD_CRUMB:
            const { breadCrumbLast } = action.payload
            return { ...state, breadCrumbLast }

        case LayoutTypes.CHANGE_USERNAME:
            const { username } = action.payload
            return { ...state, appBar: { ...state.appBar, username } }

        case LayoutTypes.CHANGE_AVATAR:
            const { avatar } = action.payload
            return { ...state, appBar: { ...state.appBar, avatar } }

        case LayoutTypes.CHANGE_PATIENT:
            const { patient } = action.payload
            return { ...state, patientSelected: patient }

        case LayoutTypes.CHANGE_THEME:
            const { themeMode } = action.payload
            return { ...state, themeMode }

        case LayoutTypes.CHANGE_ANONYMIZATION:
            const { anonymization } = action.payload
            return { ...state, anonymization }

        case LayoutTypes.UPDATE_AVAILABLE:
            const { updateAvailable, registration } = action.payload
            return { ...state, updateAvailable, registration }

        case LayoutTypes.CHANGE_BREAD_CRUMB_ROUTE:
            const { breadCrumbRoute } = action.payload
            return { ...state, breadCrumbRoute }

        default:
            return state
    }
}

export default reducer
