import { Reducer } from 'redux'
import { ActivityTypes, IActivityState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, request, success } from '../../root.types'
import PhysicalActivity from '../../../application/models/activity/physical/physical'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import IntradayTimeSeries from '../../../application/models/time.series/intraday/intraday.time.series'
import ActivityLevelSummary from '../../../application/models/activity/activity.level.summary'
import ActivityLevelClassification from '../../../application/models/activity/activity.level.classification'

export const INITIAL_STATE: IActivityState = {
    details: {
        activity: new PhysicalActivity(),
        loading: false,
        success: false,
        error: false,
        calories: {
            calories: new IntradayTimeSeries(),
            loading: false,
            success: false,
            error: false
        },
        heartRate: {
            heartRate: new IntradayHeartRate(),
            loading: false,
            success: false,
            error: false
        }
    },
    list: {
        activities: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    activityLevel: {
        loading: false,
        error: false,
        success: false,
        data: []
    },
    activityClassification: {
        loading: false,
        error: false,
        success: false,
        data: []
    }
}

export const resetList = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const resetDetails = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        details: INITIAL_STATE.details
    }
}

export const findActivityRequest = (state: IActivityState = INITIAL_STATE) => {
    return { ...state, details: request(state.details) }
}

export const findActivitySuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ activity: PhysicalActivity }>) => {
    const { activity } = action.payload
    return {
        ...state,
        details: success({ ...state.details, activity })
    }
}

export const findActivityFailure = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        details: failure(state.details)
    }
}


export const changeActivitiesPaginator = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ paginator: IPaginator }>) => {
    const { paginator } = action.payload
    return {
        ...state,
        list: { ...state.list, paginator }
    }
}

export const loadActivitiesRequest = (state: IActivityState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadActivitiesSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ activities: PhysicalActivity[], headers: any }>) => {
    const { headers, activities } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({ ...state.list, paginator, activities })
    }
}

export const loadActivitiesFailure = (state: IActivityState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreActivitiesSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ activities: PhysicalActivity[], headers: any }>) => {
    const { headers, activities } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({
            ...state.list,
            paginator,
            activities: state.list.activities.concat(activities)
        })
    }
}

export const changeDialogRemoveActivities = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ dialog: true, ids: string[] }>) => {
    const { dialog, ids } = action.payload
    return {
        ...state,
        remove: {
            ...state.remove,
            dialog,
            ids
        }
    }
}

export const removeActivitiesRequest = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        remove: request({ ...state.remove, dialog: true })
    }
}

export const removeActivitiesSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ ids: string[] }>) => {
    const { ids } = action.payload
    const activities = state
        .list
        .activities
        ?.filter((activity: PhysicalActivity) => !ids.some(removed => activity?.id === removed))
    return {
        ...state,
        remove: success({ ...INITIAL_STATE.remove }),
        list: {
            ...state.list,
            activities
        }

    }
}

export const removeActivitiesFailure = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        remove: failure(state.remove)
    }
}

export const caloriesRequest = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        details: {
            ...state.details,
            calories: request(state.details.calories)
        }
    }
}

export const caloriesSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ calories: IntradayTimeSeries }>) => {
    const { calories } = action.payload
    return {
        ...state,
        details: {
            ...state.details,
            calories: success({ ...state.details.calories, calories })
        }
    }
}

export const caloriesFailure = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        details: {
            ...state.details,
            calories: failure(state.details.calories)
        }
    }
}

export const heartRateRequest = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        details: {
            ...state.details,
            heartRate: request(state.details.heartRate)
        }
    }
}

export const heartRateSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ heartRate: IntradayHeartRate }>) => {
    const { heartRate } = action.payload
    return {
        ...state,
        details: {
            ...state.details,
            heartRate: success({ ...state.details.heartRate, heartRate })
        }
    }
}

export const heartRateFailure = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        details: {
            ...state.details,
            heartRate: failure(state.details.heartRate)
        }
    }
}

export const activityLevelRequest = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        activityLevel: request(state.activityLevel)
    }
}

export const activityLevelSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ data: ActivityLevelSummary[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        activityLevel: success({ ...state.activityLevel, data })
    }
}

export const activityLevelFailure = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        activityLevel: failure(state.activityLevel)
    }
}

export const activityClassificationRequest = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        activityClassification: request(state.activityClassification)
    }
}

export const activityClassificationSuccess = (
    state: IActivityState = INITIAL_STATE,
    action: IActionType<{ data: ActivityLevelClassification[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        activityClassification: success({ ...state.activityClassification, data })
    }
}

export const activityClassificationFailure = (state: IActivityState = INITIAL_STATE) => {
    return {
        ...state,
        activityClassification: failure(state.activityClassification)
    }
}

const reducer: Reducer<IActivityState> = createReducer<IActivityState>(INITIAL_STATE, {
    [ActivityTypes.RESET_LIST]: resetList,
    [ActivityTypes.RESET_DETAILS]: resetDetails,

    [ActivityTypes.CHANGE_PAGINATOR]: changeActivitiesPaginator,
    [ActivityTypes.CHANGE_DIALOG_REMOVE]: changeDialogRemoveActivities,

    [ActivityTypes.FIND_REQUEST]: findActivityRequest,
    [ActivityTypes.FIND_SUCCESS]: findActivitySuccess,
    [ActivityTypes.FIND_FAILURE]: findActivityFailure,

    [ActivityTypes.LOAD_REQUEST]: loadActivitiesRequest,
    [ActivityTypes.LOAD_SUCCESS]: loadActivitiesSuccess,
    [ActivityTypes.LOAD_FAILURE]: loadActivitiesFailure,

    [ActivityTypes.LOAD_MORE_REQUEST]: loadActivitiesRequest,
    [ActivityTypes.LOAD_MORE_SUCCESS]: loadMoreActivitiesSuccess,
    [ActivityTypes.LOAD_MORE_FAILURE]: loadActivitiesFailure,

    [ActivityTypes.REMOVE_REQUEST]: removeActivitiesRequest,
    [ActivityTypes.REMOVE_SUCCESS]: removeActivitiesSuccess,
    [ActivityTypes.REMOVE_FAILURE]: removeActivitiesFailure,

    [ActivityTypes.LOAD_CALORIES_REQUEST]: caloriesRequest,
    [ActivityTypes.LOAD_CALORIES_SUCCESS]: caloriesSuccess,
    [ActivityTypes.LOAD_CALORIES_FAILURE]: caloriesFailure,

    [ActivityTypes.LOAD_HEART_RATE_REQUEST]: heartRateRequest,
    [ActivityTypes.LOAD_HEART_RATE_SUCCESS]: heartRateSuccess,
    [ActivityTypes.LOAD_HEART_RATE_FAILURE]: heartRateFailure,

    [ActivityTypes.LOAD_ACTIVITY_LEVEL_SUMMARY_REQUEST]: activityLevelRequest,
    [ActivityTypes.LOAD_ACTIVITY_LEVEL_SUMMARY_SUCCESS]: activityLevelSuccess,
    [ActivityTypes.LOAD_ACTIVITY_LEVEL_SUMMARY_FAILURE]: activityLevelFailure,

    [ActivityTypes.LOAD_ACTIVITY_CLASSIFICATION_REQUEST]: activityClassificationRequest,
    [ActivityTypes.LOAD_ACTIVITY_CLASSIFICATION_SUCCESS]: activityClassificationSuccess,
    [ActivityTypes.LOAD_ACTIVITY_CLASSIFICATION_FAILURE]: activityClassificationFailure
})

export default reducer
