import { LanguageOptions } from '../../../../i18n'
import Login from './login'
import Moment from 'moment'

export enum TypesUser {
    ADMIN = 'admin',
    HEALTH_PROFESSIONAL = 'health_professional',
    CAREGIVER = 'caregiver',
    PATIENT = 'patient'
}

export const MAP_TYPE_USER_TO_ENDPOINT = {
    [TypesUser.ADMIN]: 'admins',
    [TypesUser.HEALTH_PROFESSIONAL]: 'healthprofessionals',
    [TypesUser.CAREGIVER]: 'caregivers',
    [TypesUser.PATIENT]: 'patients'
}

export enum TypeGender {
    MALE = 'male',
    FEMALE = 'female'
}

export default class User extends Login {
    public static checkEmailRegisteredError(error: { code: number, message: string }): boolean {
        return error &&
            error?.code === EMAIL_REGISTERED_ERROR.code &&
            error?.message === EMAIL_REGISTERED_ERROR.message
    }

    public static checkSigopRegisteredError(error: { code: number, message: string }): boolean {
        return error &&
            error?.code === SIGOP_REGISTERED_ERROR.code &&
            error?.message === SIGOP_REGISTERED_ERROR.message
    }

    // Required
    private _name: string | undefined
    private _birth_date: Date | undefined
    private _gender: TypeGender | undefined
    // optional
    private _phone_number: string | undefined
    private _language: LanguageOptions | undefined
    // Readonly
    private _id: string | undefined
    private _last_login: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _type: TypesUser | undefined
    private _avatar: any | undefined

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get birth_date(): Date | undefined {
        return this._birth_date
    }

    set birth_date(value: Date | undefined) {
        this._birth_date = value
    }

    get gender(): TypeGender | undefined {
        return this._gender
    }

    set gender(value: TypeGender | undefined) {
        this._gender = value
    }

    get phone_number(): string | undefined {
        return this._phone_number
    }

    set phone_number(value: string | undefined) {
        this._phone_number = value
    }

    get language(): LanguageOptions | undefined {
        return this._language
    }

    set language(value: LanguageOptions | undefined) {
        this._language = value
    }

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get last_login(): string | undefined {
        return this._last_login
    }

    set last_login(value: string | undefined) {
        this._last_login = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get type(): TypesUser | undefined {
        return this._type
    }

    set type(value: TypesUser | undefined) {
        this._type = value
    }

    get avatar(): string | undefined {
        return this._avatar
    }

    set avatar(value: string | undefined) {
        this._avatar = value
    }

    public fromJSON(json: any): User {
        super.fromJSON(json)

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.birth_date !== undefined) {
            this.birth_date = Moment(json.birth_date).toDate()
        }

        if (json.gender !== undefined) {
            this.gender = json.gender
        }

        if (json.phone_number !== undefined) {
            this.phone_number = json.phone_number
        }

        if (json.language !== undefined) {
            this.language = json.language
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.last_login !== undefined) {
            this.last_login = json.last_login
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.type !== undefined) {
            this.type = json.type
        }

        if (json.avatar !== undefined) {
            this.avatar = json.avatar
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            name: this.name ? this.name : undefined,
            birth_date: this.birth_date ? this.birth_date : null,
            gender: this.gender ? this.gender : undefined,
            phone_number: this.phone_number,
            language: this.language ? this.language : undefined,
            id: this.id ? this.id : undefined,
            last_login: this.last_login ? this.last_login : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            type: this.type ? this.type : undefined,
            avatar: this.avatar ? this.avatar : undefined
        }
    }

}

export const USER_PROTECTED_ERROR = {
    code: 400,
    message: 'The operation could not be completed as the user in question cannot be removed.'
}

export const EMAIL_REGISTERED_ERROR = {
    code: 409,
    message: 'An User with this email already registered!'
}

export const SIGOP_REGISTERED_ERROR = {
    code: 409,
    message: 'A Patient with this sigop_registration already registered!'
}

export const PATIENT_DISSOCIATE_ERROR = {
    code: 400,
    message: 'Patient cannot be disassociated!',
    description: 'This HealthProfessional is the only one who has an association with this Patient.'
}
