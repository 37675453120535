import { JsonUtils } from '../../utils/json.util'

export default class QuestionnaireStatus {
    private _has_biodemographic_record: boolean | undefined
    private _has_occupational_record: boolean | undefined
    private _has_laboratory_record: boolean | undefined
    private _has_bioimpedance_record: boolean | undefined
    private _patient_id: string | undefined

    get has_biodemographic_record(): boolean | undefined {
        return this._has_biodemographic_record
    }

    set has_biodemographic_record(value: boolean | undefined) {
        this._has_biodemographic_record = value
    }

    get has_occupational_record(): boolean | undefined {
        return this._has_occupational_record
    }

    set has_occupational_record(value: boolean | undefined) {
        this._has_occupational_record = value
    }

    get has_laboratory_record(): boolean | undefined {
        return this._has_laboratory_record
    }

    set has_laboratory_record(value: boolean | undefined) {
        this._has_laboratory_record = value
    }

    get has_bioimpedance_record(): boolean | undefined {
        return this._has_bioimpedance_record
    }

    set has_bioimpedance_record(value: boolean | undefined) {
        this._has_bioimpedance_record = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    public fromJSON(json: any): QuestionnaireStatus {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.has_biodemographic_record !== undefined) {
            this.has_biodemographic_record = json.has_biodemographic_record
        }

        if (json.has_occupational_record !== undefined) {
            this.has_occupational_record = json.has_occupational_record
        }

        if (json.has_laboratory_record !== undefined) {
            this.has_laboratory_record = json.has_laboratory_record
        }

        if (json.has_bioimpedance_record !== undefined) {
            this.has_bioimpedance_record = json.has_bioimpedance_record
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        return this
    }

    public toJSON(): any {
        return {
            has_biodemographic_record: this.has_biodemographic_record || undefined,
            has_occupational_record: this.has_occupational_record || undefined,
            has_laboratory_record: this.has_laboratory_record || undefined,
            has_bioimpedance_record: this.has_bioimpedance_record || undefined,
            patient_id: this.patient_id || undefined
        }
    }
}
