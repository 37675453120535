import { JsonUtils } from '../../utils/json.util'

export default class ActivityLevelSummary {
    private _date: string | undefined
    private _low_activity_minutes: number | undefined
    private _moderate_activity_minutes: number | undefined
    private _intense_activity_minutes: number | undefined
    private _patient_id: string | undefined

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get low_activity_minutes(): number | undefined {
        return this._low_activity_minutes
    }

    set low_activity_minutes(value: number | undefined) {
        this._low_activity_minutes = value
    }

    get moderate_activity_minutes(): number | undefined {
        return this._moderate_activity_minutes
    }

    set moderate_activity_minutes(value: number | undefined) {
        this._moderate_activity_minutes = value
    }

    get intense_activity_minutes(): number | undefined {
        return this._intense_activity_minutes
    }

    set intense_activity_minutes(value: number | undefined) {
        this._intense_activity_minutes = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    public fromJSON(json: any): ActivityLevelSummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.low_activity_minutes !== undefined) {
            this.low_activity_minutes = json.low_activity_minutes
        }

        if (json.moderate_activity_minutes !== undefined) {
            this.moderate_activity_minutes = json.moderate_activity_minutes
        }

        if (json.intense_activity_minutes !== undefined) {
            this.intense_activity_minutes = json.intense_activity_minutes
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        return this
    }

    public toJSON(): any {
        return {
            date: this.date ? this.date : undefined,
            low_activity_minutes: this.low_activity_minutes ? this.low_activity_minutes : undefined,
            moderate_activity_minutes: this.moderate_activity_minutes ? this.moderate_activity_minutes : undefined,
            intense_activity_minutes: this.intense_activity_minutes ? this.intense_activity_minutes : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined
        }
    }
}
