import moment from 'moment'
import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { TimeSeriesSimpleFilter } from '../store/application/models/time.series/filters/simple.filter'
import HeartRateVariability from '../store/application/models/measurements/heart.rate.variability'
import { MultiStatus } from '../store/application/models/multi.status/multi.status'
import { MultiStatusItem } from '../store/application/models/multi.status/multi.status.item'
import TimeSeries from '../store/application/models/time.series/time.series'

class HeartRateVariabilityService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAll(
        patientId: string,
        paginator: IPaginator,
        filter: TimeSeriesSimpleFilter): Promise<IAxiosResponse<HeartRateVariability[]>> {
        const params = this.buildParams(paginator, filter)
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/heartratevariations`, { params })
            .then((response: IAxiosResponse<any[]>) => {
                const listOfHrv: HeartRateVariability[] = response
                    ?.data
                    ?.map(measurement => new HeartRateVariability().fromJSON(measurement)) || []
                const startDate = filter?.start_date
                const endDate = filter?.end_date
                const days: string[] = TimeSeries
                    .generateDaysBetweenDates(`${startDate}`, `${endDate}`)
                    .reverse()

                const data: HeartRateVariability[] = days
                    .map((date: string) => {
                        const hrv: HeartRateVariability | undefined = listOfHrv
                            .find((element: HeartRateVariability) => element.date === `${date}`)
                        return hrv || new HeartRateVariability().fromJSON({ date })
                    })

                return {
                    data,
                    headers: response.headers
                }
            })
    }

    public getByDate(patientId: string, date?: string): Promise<HeartRateVariability> {
        const dateSelected: string = moment(date).format('YYYY-MM-DD')
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/heartratevariations/${dateSelected}`)
            .then((response: IAxiosResponse<any[]>) => new HeartRateVariability().fromJSON(response.data))
    }

    public remove(patientId: string, date: string): Promise<any> {
        const dateSelected: string = moment(date).format('YYYY-MM-DD')
        return axiosInstance
            .delete(`${this.apiVersion}/patients/${patientId}/heartratevariations/${dateSelected}`)
    }

    public requestSync(patientId: string, startDate: string, endDate: string): Promise<MultiStatus<HeartRateVariability>> {
        const body: any = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD')
        }
        return axiosInstance
            .post(`${this.apiVersion}/patients/${patientId}/heartratevariations`, body)
            .then((response: IAxiosResponse<any>) =>
                new MultiStatus<HeartRateVariability>().fromJSON({
                    success: response?.data?.success?.map((dataItem: MultiStatusItem<HeartRateVariability>) => {
                        return {
                            code: dataItem.code,
                            item: new HeartRateVariability().fromJSON(dataItem.item)
                        }
                    }),
                    error: response?.data?.error?.map((dataItem: MultiStatusItem<HeartRateVariability>) => {
                        return {
                            code: dataItem.code,
                            item: new HeartRateVariability().fromJSON(dataItem.item)
                        }
                    })
                })
            )
    }

    public buildParams(paginator?: IPaginator, filter?: TimeSeriesSimpleFilter): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key === 'name' && paginator?.search?.value)
                || (paginator?.search?.key === 'email' && paginator?.search?.value)
            if (paginator?.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator?.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        if (filter?.start_date) {
            const startDateStr: string = moment(filter?.start_date).format('YYYY-MM-DD')
            const endDateStr: string = moment(filter?.end_date).format('YYYY-MM-DD')
            if (filter?.end_date && startDateStr !== endDateStr) {
                params.append('date', `gte:${startDateStr}Z`)
                params.append('date', `lte:${endDateStr}Z`)
            } else {
                params.append('date', `${startDateStr}Z`)
            }
        }
        params.append('sort', '-date')
        return params
    }

}

export default new HeartRateVariabilityService()
