import Sleep, { SleepType } from '../store/application/models/activity/sleep/sleep'
import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { TimeSeriesSimpleFilter } from '../store/application/models/time.series/filters/simple.filter'

class SleepService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAll(patientId: string, paginator?: IPaginator, filter?: TimeSeriesSimpleFilter): Promise<any> {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (filter?.start_date) ||
                (filter?.end_date) ||
                (filter?.type)
            if (paginator.page || !hasSearch) {
                params.append('page', String((paginator?.page || 0) + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

        }

        if (filter) {
            if (filter?.start_date) {
                const endDate = filter?.end_date ? filter?.end_date : filter?.start_date
                params.append('end_time', `gte:${filter.start_date}T00:00:00`)
                params.append('end_time', `lte:${endDate}T23:59:59`)
            }

            if (filter?.type === SleepType.CLASSIC || filter?.type === SleepType.STAGES) {
                params.append('type', `${filter.type}`)
            }
        }

        params.append('sort', '-end_time')

        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/sleep`, { params })
            .then((response: IAxiosResponse<Sleep[]>) => {
                const { data, headers } = response
                return { data: data.map((item: Sleep) => new Sleep().fromJSON(item)), headers }
            })
    }

    public getById(patientId: string, sleepId: string): Promise<Sleep> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/sleep/${sleepId}`)
            .then((response: IAxiosResponse<any>) => new Sleep().fromJSON(response.data))
    }

    public remove(patientId: string, sleepId: string): Promise<void> {
        return axiosInstance
            .delete(`${this.apiVersion}/patients/${patientId}/sleep/${sleepId}`)
    }

}

export default new SleepService()
