import { Reducer } from 'redux'
import { createReducer } from 'reduxsauce'
import { PatientMetricsTypes, IPatientMetricsState } from './types'
import { failure, IActionType, request, success } from '../root.types'
import PatientMetrics from '../../application/models/patient.metrics/patient.metrics'
import QuestionnaireStatus from '../../application/models/questionnaires/questionnaire.status'

export const INITIAL_STATE: IPatientMetricsState = {
    list: {
        data: [],
        loading: false,
        error: false,
        success: false,
    },
    incomplete: {
        data: [],
        loading: false,
        error: false,
        success: false
    }
}

export const reset = () => INITIAL_STATE


export const loadRequest = (state: IPatientMetricsState = INITIAL_STATE) => {
    return {
        ...state,
        list: request(state.list)
    }
}

export const loadSuccess = (
    state: IPatientMetricsState = INITIAL_STATE,
    action: IActionType<{ data: PatientMetrics[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        list: success({ ...state.list, data })
    }
}

export const loadFailure = (state: IPatientMetricsState = INITIAL_STATE) => {
    return {
        ...state,
        list: failure(state.list)
    }
}


export const loadIncompleteRequest = (state: IPatientMetricsState = INITIAL_STATE) => {
    return {
        ...state,
        incomplete: request(state.incomplete)
    }
}

export const loadIncompleteSuccess = (
    state: IPatientMetricsState = INITIAL_STATE,
    action: IActionType<{ data: QuestionnaireStatus[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        incomplete: success({ ...state.incomplete, data })
    }
}

export const loadIncompleteFailure = (state: IPatientMetricsState = INITIAL_STATE) => {
    return {
        ...state,
        incomplete: failure(state.incomplete)
    }
}

const reducer: Reducer<IPatientMetricsState> = createReducer<IPatientMetricsState>(
    INITIAL_STATE, {
    [PatientMetricsTypes.RESET]: reset,
    [PatientMetricsTypes.LOAD_REQUEST]: loadRequest,
    [PatientMetricsTypes.LOAD_SUCCESS]: loadSuccess,
    [PatientMetricsTypes.LOAD_FAILURE]: loadFailure,
    [PatientMetricsTypes.LOAD_INCOMPLETE_REQUEST]: loadIncompleteRequest,
    [PatientMetricsTypes.LOAD_INCOMPLETE_SUCCESS]: loadIncompleteSuccess,
    [PatientMetricsTypes.LOAD_INCOMPLETE_FAILURE]: loadIncompleteFailure
})

export default reducer
